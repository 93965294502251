<template>
  <!-- テンプレート登録ダイアログ -->
  <div>
    <ValidationObserver ref="observer">
      <h3 id="title_head">タイトル</h3>
      <div class="mt-2 mb-2">
        文字数制限のあるコンテンツにあわせて入力してください。
      </div>
      <v-card tile elevation="0">
        <v-tabs v-model="title_tab" show-arrows height="32" :hide-slider="true">
          <v-tab
            v-for="tab in item.title"
            :key="'tt' + tab.index"
            active-class="blue lighten-5 font-weight-bold"
            :class="`${
              tab.value ? 'font-weight-bold text-blue-important' : ''
            }`"
          >
            {{ tab.name }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="title_tab" touchless>
          <v-tab-item
            v-for="tab in item.title"
            :key="'ti' + tab.index"
            class="blue lighten-5 pt-2"
          >
            <div class="ma-3 mb-0">
              <ValidationProvider
                v-slot="{ errors, valid }"
                name="タイトル"
                :rules="'max:' + tab.counter"
              >
                <v-text-field
                  v-model="tab.value"
                  :name="tab.el_name"
                  :rules="tab.rules"
                  :counter="tab.counter"
                  :error-messages="errors"
                  :success="valid"
                  :hint="tab.hint"
                  label="タイトル"
                  outlined
                  background-color="white"
                ></v-text-field>
              </ValidationProvider>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-card>

      <h3 id="body_head">内容【テキスト用】</h3>
      <div class="mt-2 mb-2">
        文字数制限のあるコンテンツにあわせて入力してください。
      </div>
      <v-card tile elevation="0">
        <v-tabs v-model="body_tab" show-arrows height="32" :hide-slider="true">
          <v-tab
            v-for="tab in item.body"
            :key="'bt' + tab.index"
            active-class="blue lighten-5 font-weight-bold"
            :class="`${
              tab.value ? 'font-weight-bold text-blue-important' : ''
            }`"
          >
            <div v-if="tab.type == 'TEXT'">
              {{ tab.name }}
            </div>
            <div v-else>HTML</div>
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="body_tab">
          <v-tab-item
            v-for="tab in item.body"
            :key="'bi' + tab.index"
            :eager="true"
            class="blue lighten-5 pt-2"
          >
            <div v-if="tab.type == 'TEXT'">
              <div class="ma-3 mb-0">
                <ValidationProvider
                  v-slot="{ errors, valid }"
                  name="内容"
                  :rules="'max:' + tab.counter"
                >
                  <v-textarea
                    v-model="tab.value"
                    :name="tab.el_name"
                    :rules="tab.rules"
                    :counter="tab.counter"
                    :error-messages="errors"
                    :success="valid"
                    :hint="tab.hint"
                    label="内容"
                    rows="12"
                    outlined
                    background-color="white"
                  />
                </ValidationProvider>
              </div>
            </div>
            <div v-else>
              <div>
                <ckeditor-component
                  ref="refCkeditor"
                  :data="tab.value"
                ></ckeditor-component>
              </div>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
      <h3 id="body_head">内容【HTML用】</h3>
      <div class="mt-2 mb-2">
        HTMLが使用できるコンテンツ用に入力してください。<v-btn
          text
          color="primary"
          class="font-weight-bold pa-1 d-inline"
          @click="onHtmlClear"
          >HTMLをクリア</v-btn
        >
      </div>
      <v-card tile elevation="0" class="mb-6">
        <div class="ma-3">
          <ckeditor-component
            ref="refCkeditor"
            :data="item.body_html"
          ></ckeditor-component>
        </div>
      </v-card>
      <!-- 画像選択コンポーネント -->
      <!-- <imageComponent
        ref="refImageComponent"
        :template_config="template_config"
      ></imageComponent> -->
      <tabImageComponent
        ref="refTabImageComponent"
        :template_config="template_config"
        :is_contain="true"
      ></tabImageComponent>
    </ValidationObserver>
  </div>
</template>

<script>
import {
  set,
  ref,
  reactive,
  toRefs,
  defineComponent,
  onMounted,
  watch,
} from "@vue/composition-api";
import setting from "@/common/setting.js";
// import imageComponent from "./itemComponent/image";
import tabImageComponent from "./itemComponent/tabImage";

export default defineComponent({
  components: {
    // imageComponent,
    tabImageComponent,
  },
  props: ["template_config"],
  setup(props, ctx) {
    const title_tab = ref(null);
    const body_tab = ref(null);
    const refCkeditor = ref(null);
    const state = reactive({
      item: {
        title: [],
        body: [],
        body_html: "",
      },
      title_tab_counter: [1000, 10, 15, 20, 25, 30, 40, 50, 100],
      body_tab_counter: [10000, 100, 200, 300, 400, 500, 1000, 1500],
    });

    const clearDialog = async () => {
      Object.keys(state.item.title).forEach(function (key) {
        set(state.item.title[key], "value", "");
      });
      Object.keys(state.item.body).forEach(function (key) {
        set(state.item.body[key], "value", "");
      });
      state.item.body_html = "";
      title_tab.value = 0;
      body_tab.value = 0;
      if (refCkeditor.value) {
        refCkeditor.value.editorData = "";
      }
    };

    const setData = async () => {
      // 各項目に値をセット
      const config = props.template_config;
      for (const [key, value] of Object.entries(config)) {
        const keyItem = key.split("_");
        if (keyItem[0] == "title" || keyItem[0] == "body") {
          Object.keys(state.item[keyItem[0]]).forEach(function (key) {
            if (state.item[keyItem[0]][key].counter == keyItem[1]) {
              set(state.item[keyItem[0]][key], "value", value);
            }
            if ("html" == keyItem[1]) {
              set(state.item, "body_html", value);
              if (refCkeditor.value) {
                refCkeditor.value.editorData = value;
              }
            }
          });
        }
      }
    };

    onMounted(async () => {
      // 初期化
      await clearDialog();
      // データセット
      if (props.template_config) {
        await setData(props.template_config);
      }
    });

    const init = () => {
      // タイトル名・初期化
      state.item.title.slice(0);
      state.title_tab_counter.forEach((counter, index) => {
        state.item.title.push({
          index: index,
          value: "",
          name: counter == 1000 ? "基本" : counter + "文字",
          el_name: "title_" + index,
          rules: [
            (v) => v.length <= counter || "最大" + counter + "文字までです",
          ],
          counter: counter,
          hint: counter + "文字以内でタイトルを入力してください",
        });
      });
      // 内容・初期化
      state.item.body.slice(0);
      state.body_tab_counter.forEach((counter, index) => {
        state.item.body.push({
          index: index,
          type: "TEXT",
          value: "",
          name: counter == 10000 ? "基本" : counter + "文字",
          el_name: "body_" + index,
          rules: [
            (v) => v.length <= counter || "最大" + counter + "文字までです",
          ],
          counter: counter,
          hint: counter + "文字以内で内容を入力してください",
        });
      });
    };

    init();

    // 保存前のチェック処理
    const checkValid = async () => {
      // 入力チェック エラー状態の部品が無いか調べる
      let isValid = await ctx.refs.observer.validate();

      let imageConfigIsValid = await ctx.refs.refTabImageComponent.checkValid();
      if (!imageConfigIsValid) {
        isValid = false;
      }

      const el = document.querySelectorAll(
        ".v-text-field.error--text, .v-textarea.error--text"
      );
      // エラー状態の部品あり(inputタグかtextareaタグか、エラーが出ている箇所を切り分けてから処理)
      if (el.length != 0) {
        isValid = false;
        let tag = "input";
        if (document.querySelectorAll(".v-textarea.error--text").length != 0) {
          tag = "textarea";
        }
        const item_name = el[0]
          .querySelector(tag)
          .getAttribute("name")
          .split("_")[0];
        const item_index = Number(
          el[0].querySelector(tag).getAttribute("name").split("_")[1]
        );
        if (item_name == "title") {
          document.querySelector("#title_head").scrollIntoView();
          title_tab.value = item_index;
        } else if (item_name == "body") {
          document.querySelector("#body_head").scrollIntoView();
          body_tab.value = item_index;
        } else {
          el[0].scrollIntoView();
        }
      }
      return isValid;
    };

    // 保存処理
    const onSubmit = async () => {
      // 保存処理
      const config = {};
      Object.keys(state.item.title).forEach(function (key) {
        config["title_" + state.item.title[key].counter] =
          state.item.title[key].value;
      });
      Object.keys(state.item.body).forEach(function (key) {
        if (state.item.body[key].type == "TEXT") {
          // CKEditor以外
          config["body_" + state.item.body[key].counter] =
            state.item.body[key].value;
        }
      });
      if (refCkeditor.value) {
        config["body_html"] = refCkeditor.value.editorData;
      } else {
        config["body_html"] = state.item.body_html;
      }

      // 画像コンポーネントの設定を取得
      const tabImageConfig = await ctx.refs.refTabImageComponent.saveConfig();

      return JSON.stringify({ ...config, ...tabImageConfig });
    };

    const onHtmlClear = () => {
      if (
        !window.confirm("HTMLに入力された内容をクリアします。よろしいですか？")
      ) {
        return;
      }
      set(state.item, "body_html", "");
      if (refCkeditor.value) {
        refCkeditor.value.editorData = "";
      }
    };

    // 返却オブジェクト定義
    return {
      setting,
      title_tab,
      body_tab,
      refCkeditor,
      ...toRefs(state),
      onHtmlClear,
      checkValid,
      onSubmit,
    };
  },
});
</script>

<style scoped>
::v-deep #color-selector .v-input__append-inner {
  margin-top: 13px;
}
.edit_area {
  cursor: pointer;
}
::v-deep .td_content_name {
  width: 160px;
}
::v-deep .td_site_content_name {
  min-width: 160px;
}
.image_flame {
  overflow: auto;
}
.image_box {
  display: flex;
}
.image_card {
  margin-right: 0.25em;
  position: relative;
}
.image_drag_btn {
  position: absolute;
  top: 3px;
  right: 3px;
  z-index: 2;
}
</style>
